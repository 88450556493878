<template>
	<div class="weekly-leaderboard-component py-3" id="sectionLeaderboard">
		<div id="WeeklyLeaderboardBanner" class="banner">
			<v-img src="@/assets/images/leaderboard/3-medal.webp" />
		</div>

		<!-- Unqualified Title and subtitle -->
		<h2 class="ortho-title" v-if="!passedFirstCondition || !passedSecondCondition">Rejoins la communauté !</h2>
		<p class="sub-title text-center" v-if="!passedFirstCondition">
			Valide encore {{ totalSessionsOfCurrentCategory - numbPassedSessions }} session{{
				totalSessionsOfCurrentCategory - numbPassedSessions > 1 ? 's' : ''
			}}
			pour rejoindre le classement
		</p>
		<p class="sub-title text-center" v-else-if="!passedSecondCondition">
			Valide une session pour participer au classement hebdomadaire
		</p>
		<!-- End Unqualified Title and subtitle -->

		<div class="content">
			<UnqualifiedPlaceholder
				v-if="!passedFirstCondition || !passedSecondCondition"
				:passedFirstCondition="passedFirstCondition"
				:profileInfos="formationProgress.user"
			/>
			<QualifiedWeeklyLeaderBoard v-else class="mt-5" :myFormationId="formationProgress.formation._id" />
		</div>
	</div>
</template>

<script>
import UnqualifiedPlaceholder from './UnqualifiedPlaceholder';
import QualifiedWeeklyLeaderBoard from './QualifiedWeeklyLeaderBoard';
import { ParcoursItemStatus } from '@/constants/parcours.js';
import { CategoryStatus } from '@/constants/category.js';
import { checkDateWithinThisWeek } from '@/utils/time.js';

export default {
	name: 'WeeklyLeaderBoardOtherTopic',
	components: {
		UnqualifiedPlaceholder,
		QualifiedWeeklyLeaderBoard,
	},
	props: {
		formationProgress: Object,
	},
	data() {
		return {
			passedFirstCondition: false,
			passedSecondCondition: false,
			numbPassedSessions: 0,
			totalSessionsOfCurrentCategory: 0,
		};
	},
	mounted() {
		this.checkQualified(this.formationProgress);
	},
	methods: {
		checkQualified(fProgress) {
			if (fProgress == null) return;
			const currentCategoryId = fProgress.formation.last_category;
			let totalSessionsOfCurrentCategory = 0;

			let passedFirstCondition = false; // completed at least 1 category
			let passedSecondCondition = false; // passed 1 session this week
			let numbPassedSessions = 0;

			fProgress.config.parcours_list.forEach((parcoursItem) => {
				if (parcoursItem.status === ParcoursItemStatus.completed) passedFirstCondition = true;
				parcoursItem.parcours_id.categories.forEach((category) => {
					if (category._id === currentCategoryId) {
						totalSessionsOfCurrentCategory = category.sessions.length;
					}

					if (category.status === CategoryStatus.COMPLETED) passedFirstCondition = true;
					category.sessions.forEach((session) => {
						if (passedFirstCondition && passedSecondCondition) return; // Reduce looping times

						if (session.score < 80) return;
						numbPassedSessions += 1;

						if (checkDateWithinThisWeek(session.passedDate)) passedSecondCondition = true; // Check session.passedDate is within this week
					});
				});
			});

			this.passedFirstCondition = passedFirstCondition;
			this.passedSecondCondition = passedSecondCondition;
			this.numbPassedSessions = numbPassedSessions;
			this.totalSessionsOfCurrentCategory = totalSessionsOfCurrentCategory;
		},
	},
};
</script>

<style lang="scss" scoped>
.weekly-leaderboard-component {
	display: flex;
	flex-direction: column;
	align-items: center;
	* {
		font-family: 'Lato';
	}
	.banner {
		display: flex;
		align-items: center;
		width: 70%;
		max-width: 350px;
		@media screen and (max-width: 960px) {
			width: 50%;
		}
	}
	.sub-title {
		font-size: 17px;
		color: #808080;
		font-weight: bold;
	}

	.content {
		width: 100%;
		@media screen and (max-width: 960px) {
			width: 99%;
		}
	}
}
</style>
